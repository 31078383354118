<template>
  <r-e-dialog
    title="水电表抄表"
    class="layer-contract-detail"
    :visible.sync="dialogVisible"
    width="710px"
    top="10vh"
   
   
    @close="clickCancel"
  >
    <div
      class="flex justify-between align-center flex-warp"
      style="
        background-color: #eff9ff;
        height: 50px;
        color: #333333;
        font-size: 15px;
      "
    >
      <div style="margin-left: 20px">房间号 : {{ equipmentData.name }}</div>
    </div>
    <el-form
      ref="formPublish"
      label-width="135px"
      size="small"
      :model="formPublish"
      :rules="rules"
    >
      <div class="flex">
        <el-form-item label="类型" prop="type">
          <div style="width: 190px">
            <el-radio-group v-model="formPublish.type">
              <el-radio :label="1">手动</el-radio>
              <el-radio :label="2">自动</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="是否生成账单" prop="isBill">
          <div style="width: 190px">
            <el-radio-group v-model="formPublish.isBill">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
      </div>
      <div style="border-left: #2a8af7 5px solid; padding-left: 5px">
        设备编号
      </div>
      <div class="flex" style="height: 25px">
        <el-form-item label="水表编号">
          <div style="width: 190px">{{ equipmentData.waterNum }}</div>
        </el-form-item>
        <el-form-item label="电表编号">
          <div style="width: 190px">{{ equipmentData.electNum }}</div>
        </el-form-item>
      </div>
      <div class="flex" style="height: 30px">
        <el-form-item label="水表数据来源">
          <div style="width: 190px">
            <el-tag
              :type="
                formPublish.waterDataSources === 0
                  ? ''
                  : formPublish.waterDataSources === 1
                  ? 'success'
                  : 'warning'
              "
            >
              {{
                formPublish.waterDataSources === 0
                  ? '设备'
                  : formPublish.waterDataSources === 1
                  ? '数据库'
                  : '人工输入'
              }}
            </el-tag>
          </div>
        </el-form-item>
        <el-form-item label="电表数据来源">
          <div style="width: 190px">
            <el-tag
              :type="
                formPublish.electDataSources === 0
                  ? ''
                  : formPublish.electDataSources === 1
                  ? 'success'
                  : 'warning'
              "
            >
              {{
                formPublish.electDataSources === 0
                  ? '设备'
                  : formPublish.electDataSources === 1
                  ? '数据库'
                  : '人工输入'
              }}
            </el-tag>
          </div>
        </el-form-item>
      </div>
      <div style="border-left: #2a8af7 5px solid; padding-left: 5px">
        上次抄表
      </div>
      <div class="flex">
        <el-form-item label="上次水表读数" prop="lastWater" class="itemWater">
          <el-input-number
            v-model="formPublish.lastWater"
            :precision="2"
            :step="1.0"
            :min="0"
            style="width: 190px"
            :disabled="formPublish.type === 2"
            @change="getHydropowerBill"
          />
        </el-form-item>
        <el-form-item label="上次电表读数" prop="lastElect" class="itemElect">
          <el-input-number
            v-model="formPublish.lastElect"
            :precision="2"
            :step="1.0"
            :min="0"
            style="width: 190px"
            :disabled="formPublish.type === 2"
            @change="getHydropowerBill"
          />
        </el-form-item>
      </div>
      <div style="border-left: #2a8af7 5px solid; padding-left: 5px">
        当前抄表
      </div>
      <div class="flex">
        <el-form-item label="当前水表读数" prop="water" class="itemWater">
          <el-input-number
            v-model="formPublish.water"
            :precision="2"
            :step="1.0"
            :min="0"
            style="width: 190px"
            :disabled="formPublish.type === 2"
            @change="getHydropowerBill"
          />
        </el-form-item>
        <el-form-item label="当前电表读数" prop="elect" class="itemElect">
          <el-input-number
            v-model="formPublish.elect"
            :precision="2"
            :step="1.0"
            :min="0"
            style="width: 190px"
            :disabled="formPublish.type === 2"
            @change="getHydropowerBill"
          />
        </el-form-item>
      </div>
      <div style="border-left: #2a8af7 5px solid; padding-left: 5px">
        使用数量
      </div>
      <div class="flex">
        <el-form-item label="水表使用读数">
          <el-input-number
            :value="formPublish.water - formPublish.lastWater"
            :precision="2"
            :step="1.0"
            :min="0"
            style="width: 190px"
            disabled
          />
        </el-form-item>
        <el-form-item label="电表使用读数">
          <el-input-number
            :value="formPublish.elect - formPublish.lastElect"
            :precision="2"
            :step="1.0"
            :min="0"
            style="width: 190px"
            disabled
          />
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="水费单价m³/元">
          <el-input-number
            v-model="formPublish.priceWater"
            :precision="2"
            :step="1.0"
            disabled
            style="width: 190px"
          />
        </el-form-item>
        <el-form-item label="电费单价KW·h/元">
          <el-input-number
            v-model="formPublish.priceElect"
            :precision="2"
            :step="1.0"
            disabled
            style="width: 190px"
          />
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="当前水费合计(元)">
          <el-input-number
            v-loading="loading"
            v-model="formPublish.amountWater"
            :precision="2"
            :step="1.0"
            disabled
            style="width: 190px"
          />
        </el-form-item>
        <el-form-item label="当前电费合计(元)">
          <el-input-number
            v-loading="loading"
            v-model="formPublish.amountElect"
            :precision="2"
            :step="1.0"
            disabled
            style="width: 190px"
          />
        </el-form-item>
      </div>
      <div class="flex" v-if="formPublish.type === 1">
        <el-form-item label="上次抄表时间" prop="lastTime">
          <el-date-picker
            v-model="formPublish.lastTime"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            style="width: 190px"
          />
        </el-form-item>
        <el-form-item label="当前抄表时间" prop="createdTime">
          <el-date-picker
            v-model="formPublish.createdTime"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            style="width: 190px"
          />
        </el-form-item>
      </div>
    </el-form>  
    <div class="submit-btn-group" style="text-align: center;" >
            <el-button class="cancel"  @click="clickCancel" >
                取消
            </el-button>
            <el-button class="submit"  @click="clickSubmit" :disabled="disableSubmitButton">
                确定
            </el-button>
    </div>
    
  </r-e-dialog>
</template>

<script>
import { hydropowerBill, hydropowerSettlement } from '@/api/door-management'
import { MessageSuccess } from '@custom/message'
import { timeFormat, ParseFloatFormat } from '@custom/index'
export default {
  name: 'dialog-add-surface',
  data () {
    return {
      dialogVisible: false,
      formPublish: {
        type: 2,
        lastWater: '',
        lastElect: '',
        lastTime: '',
        water: '',
        elect: '',
        createdTime: '',
        priceWater: '',
        priceElect: '',
        amountWater: '',
        amountElect: '',
        isBill: 2,
        waterDataSources: 0,
        electDataSources: 0,
        transactionCode:'', // 随机码
      },
      rules: {
        lastWater: [
          { required: true, message: '上次水表读数不能为空', trigger: 'blur' }
        ],
        lastElect: [
          { required: true, message: '上次电表读数不能为空', trigger: 'blur' }
        ],
        water: [
          { required: true, message: '当前水表读数不能为空', trigger: 'blur' }
        ],
        elect: [
          { required: true, message: '当前电表读数不能为空', trigger: 'blur' }
        ],
        createdTime: [
          { required: true, message: '当前抄表时间不能为空', trigger: 'change' }
        ],
        lastTime: [
          { required: true, message: '上次抄表时间不能为空', trigger: 'change' }
        ]
      },
      loading: true,
      loadingOptions: {
        lock: true,
        text: 'Loading...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      },
      disableSubmitButton:false,
    }
  },
  props: {
    equipmentData: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    async openDialog () {
      let that = this
      let oldFormPublish = this.formPublish
      let transactionCode = Math.random();
      that.formPublish.transactionCode = transactionCode
      const { uuid } = this.equipmentData
      const loadingOptions = that.loadingOptions
      const loading = that.$loading({ ...loadingOptions })
      let res = await hydropowerBill({ uuid, type: 2 }).catch(err =>
        loading.close()
      )
      if (res) {
        let {
          info: {
            lastElect,
            lastWater,
            elect,
            water,
            priceWater,
            priceElect,
            amountWater,
            amountElect,
            lastTime,
            createTime,
            waterDataSources,
            electDataSources
          }
        } = res
        priceWater = ParseFloatFormat(priceWater)
        priceElect = ParseFloatFormat(priceElect)
        amountWater = ParseFloatFormat(amountWater)
        amountElect = ParseFloatFormat(amountElect)
        waterDataSources = parseInt(waterDataSources)
        electDataSources = parseInt(electDataSources)
        lastTime = lastTime
          ? timeFormat(new Date(lastTime))
          : timeFormat(new Date())
        let createdTime = createTime
          ? timeFormat(new Date(createTime))
          : timeFormat(new Date())
        that.formPublish = {
          ...oldFormPublish,
          lastElect,
          lastWater,
          elect,
          water,
          type: 2,
          priceWater,
          priceElect,
          amountWater,
          amountElect,
          lastTime,
          createdTime,
          waterDataSources,
          electDataSources
        }
      }
      loading.close()
      that.loading = false
      this.dialogVisible = true
      // 2023/08/16张晓瑜新增弹窗提示
      let isAlertShown = false
      if (
        (this.formPublish.elect === -1 && this.formPublish.electDataSources === 1) ||
        (this.formPublish.water === -1 && this.formPublish.waterDataSources === 1)
      ) {
        let message = ''
        if (
          this.formPublish.elect === -1 && this.formPublish.electDataSources === 1
        ) {
          message += '电表'
        }
        if (
          this.formPublish.water === -1 && this.formPublish.waterDataSources === 1
        ) {
          if (message !== '') {
            message += '和水表都'
          } else {
            message += '水表'
          }
        }

        if (!isAlertShown) {
          this.$alert(message + '不在线，请手动进行输入', '提示', {
            confirmButtonText: '确定'
          })
          this.formPublish.type = 1
          isAlertShown = true
        }
      }
    },
    clickSubmit() {
      let that = this;
      if (that.disableSubmitButton) {
        // 如果按钮已被禁用，则直接返回
        return;
      }
      this.$refs['formPublish'].validate(valid => {
        if (valid) {
          // 禁用按钮
          that.disableSubmitButton = true;
          const loadingOptions = that.loadingOptions;
          const loading = that.$loading({ ...loadingOptions });
          let { uuid } = that.equipmentData;
          let {
            lastElect,
            lastWater,
            elect,
            water,
            type,
            isBill,
            lastTime,
            createdTime,
            transactionCode
          } = that.formPublish;
          let paramsData = {
            uuid,
            lastElect,
            lastWater,
            elect,
            water,
            type,
            isBill,
            lastTime,
            createdTime,
            transactionCode
          };
          hydropowerSettlement(paramsData)
            .then(res => {
              MessageSuccess('结算成功');
              loading.close();
              // 启用按钮
              that.disableSubmitButton = false;
              that.clickCancel();
            })
            .catch(err => {
              loading.close();
              that.disableSubmitButton = false;
            });
        }
      });
},
    clickCancel () {
      this.$emit('handleSearch', false)
      this.dialogVisible = false
    },
    async getHydropowerBill () {
      let that = this
      const {
        lastElect,
        lastWater,
        elect,
        water,
        isBill,
        lastTime,
        createdTime,
        type,
        waterDataSources,
        electDataSources
      } = that.formPublish
      const { uuid } = this.equipmentData
      that.loading = true
      const data = {
        uuid,
        type,
        lastElect,
        lastWater,
        elect,
        water,
        isBill,
        lastTime,
        createdTime,
        waterDataSources,
        electDataSources
      }
      let res = await hydropowerBill(data).catch(err => {})
      if (res) {
        let {
          info: { amountWater, amountElect },
          info
        } = res
        amountWater = ParseFloatFormat(amountWater)
        amountElect = ParseFloatFormat(amountElect)
        that.formPublish.amountWater = amountWater
        that.formPublish.amountElect = amountElect
        that.formPublish.lastElect = info.lastElect
        that.formPublish.lastWater = info.lastWater
        that.formPublish.elect = info.elect
        that.formPublish.water = info.water
        that.formPublish.waterDataSources = info.waterDataSources
        that.formPublish.electDataSources = info.electDataSources
      }
      that.loading = false
    }
  },
  watch: {
    'formPublish.type' (value) {
      this.getHydropowerBill()
    }
  }
}
</script>

<style lang="scss" scoped>
.itemWater /deep/ .el-form-item__label {
  color: blue !important;
  font-size: 16.5px !important;
}

.itemElect /deep/ .el-form-item__label {
  color: green !important;
  font-size: 16.5px !important;
}

</style>
